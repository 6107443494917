



































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SesionInformaComponent extends Vue {
  private transmision: any = "";
  private videoIdTransmision: any = "";
  private verTransmision: boolean = false;
  private municipioInforma: Array<any> = [];

  private created() {
    this.getTransmision();
    this.getMunicipioInforma();
  }

  private getMunicipioInforma() {
    this.$q.loading.show({
      // delay: 100, // ms
    });
    this.$axios
      .get("/servicios/general?tipo=municipio-informa")
      .then((res: any) => {
        // console.log(res);
        this.municipioInforma = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private getTransmision() {
    this.$axios
      .get("/transmision")
      .then((res: any) => {
        if (res.data != "") {
          let youtubeUrl = res.data;
          let videoId = this.extractVideoId(youtubeUrl);
          this.videoIdTransmision = videoId;
          // console.log("videoIdTransmision", this.videoIdTransmision);
          this.transmision = `https://www.youtube.com/embed/${videoId}`;
        } else {
          this.transmision = "";
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private get thumbnailUrl(): string {
    return `//i.ytimg.com/vi/${this.videoIdTransmision}/hqdefault.jpg`;
  }

  private extractVideoId(url) {
    const match = url.match(
      /(?:\?v=|&v=|youtu\.be\/|\/embed\/|\/v\/|\/\d{1,2}\/|\/embed\?v=)([^#&?]{11})/
    );
    return match && match[1];
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
}
